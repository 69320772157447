import React from "react"
import { Helmet } from "react-helmet"
import moment from "moment"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../graphql/fragments"

import { Breadcrumbs } from "../../components/breadcrumbs/breadcrumbs"
import { CoverMain } from "../../components/cover-main/cover-main"
import { HeadingBasic } from "../../components/heading/heading"
// import { TabHeader } from "../../components/tab-header/tab-header"
import { NavTabs, TabPane } from "../../components/ak-tabs/tabs"
import { Container } from "../../components/grid/grid"

import Document from "../../components/document/document"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

/* ==========================================================================
    Functions
   ========================================================================== */
const groupBy = (array, key) => {
  if (array) {
    return array.reduce((result, currentValue) => {
      ;(result[currentValue["node"]["acfFile"][key]] =
        result[currentValue["node"]["acfFile"][key]] || []).push(currentValue)
      return result
    }, {})
  }
}

const compareCompanyName = (a, b) => {
  const reportA = a.key
  const reportB = b.key

  let comparison
  if (reportA > reportB) {
    comparison = 1
  } else if (reportA < reportB) {
    comparison = -1
  }

  return comparison
}

const compareByYear = (a, b) => {
  const reportA = a.name
  const reportB = b.name

  let comparison
  if (reportA < reportB) {
    comparison = 1
  } else if (reportA > reportB) {
    comparison = -1
  }

  return comparison
}

/* ==========================================================================
    Component
   ========================================================================== */
const Reports = ({ data }) => {
  const dataGroupedByCompany = data && groupBy(data, "company")
  const dataArr =
    (dataGroupedByCompany &&
      Object.keys(dataGroupedByCompany).map(key => [
        key,
        dataGroupedByCompany[key],
      ])) ||
    []

  let documentItems = []

  dataArr.length > 0 &&
    dataArr.map((dataItem, i) => {
      let releaseItems = []
      dataItem[1].map(item =>
        releaseItems.push({
          title: item.node.title || "Title",
          linkTo:
            item.node.acfFile.file !== null &&
            item.node.acfFile.file.mediaItemUrl,
          fileSize:
            item.node.acfFile.file !== null && item.node.acfFile.file.filesize,
          fileId:
            item.node.acfFile.file != null && item.node.acfFile.file.databaseId,
          date: `${moment(item.node.date).format("DD MMMM YYYY")} • ${moment(
            item.node.date
          ).format("HH:mm")}`,
        })
      )

      documentItems.push(
        <div key={`${dataItem[0]}-${i}`} className="mb-5">
          <Document
            section={dataItem[0] !== "null" && dataItem[0]}
            data={releaseItems}
          />
        </div>
      )

      return 1
    })

  return (
    <>{documentItems.length > 0 && documentItems.sort(compareCompanyName)}</>
  )
}

/* ==========================================================================
    Render
   ========================================================================== */
const PressRelease = location => {
  const data = useStaticQuery(graphql`
    query {
      imgCoverMain: file(relativePath: { eq: "imgBannerSinarmas.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      wordPress {
        pageBy(uri: "press-release") {
          title
          slug
          blocks {
            ...CoreGroupBlock
            ...CoreHeadingBlock
            ...CoreButtonBlock
            ...CoreCoverBlock
            ...CoreShortcodeBlock
            ...AcfIconListBlock
            ...CoreGroupBlock
          }
          translation(language: ID) {
            title
            blocks {
              ...CoreParagraphBlock
              ...CoreHeadingBlock
              ...CoreButtonBlock
              ...CoreCoverBlock
              ...CoreShortcodeBlock
              ...AcfIconListBlock
              ...CoreGroupBlock
            }
          }
          seo {
            title
            metaDesc
            twitterTitle
            twitterDescription
            opengraphTitle
            opengraphDescription
          }
        }
        pressRelease: fileCategory(id: "pressrelease", idType: SLUG) {
          slug
          filesPdf(first: 500) {
            edges {
              cursor
              node {
                title
                acfFile {
                  company
                  year
                  file {
                    databaseId
                    guid
                    filesize
                    mediaItemUrl
                    date
                  }
                }
                date
              }
            }
            pageInfo {
              hasPreviousPage
              hasNextPage
              startCursor
              endCursor
            }
          }
        }
      }
    }
  `)

  const wordPress = data?.wordPress?.pageBy?.blocks
  const seo = data.wordPress.pageBy.seo
  const cover =
    wordPress &&
    wordPress.find(x => x.name === "core/cover") === wordPress[0] &&
    wordPress[0]

  const releaseGroupedByYear =
    data.wordPress.pressRelease &&
    groupBy(data.wordPress.pressRelease.filesPdf.edges, "year")

  let navTab = []

  const pressReleaseArr =
    (releaseGroupedByYear &&
      Object.keys(releaseGroupedByYear).map(key => [
        key,
        releaseGroupedByYear[key],
      ])) ||
    []

  pressReleaseArr.map(item => {
    navTab.push({ name: item[0], target: `press${item[0]}` })
    return 1
  })

  return (
    <Layout location={location} currentLocation={location.uri} lang="EN">
      <SEO
        title={seo?.title}
        fbTitle={seo?.opengraphTitle}
        twitterTitle={seo?.twitterTitle}
        twitterDescription={seo?.twitterDescription}
        fbDescription={seo?.opengraphDescription}
        description={seo?.opengraphDescription}
        url={location?.href}
        fbImg={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
        twitterImg={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
        img={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
      />
      <Helmet>
        <body className="bd-page" />
      </Helmet>

      {cover && (
        <CoverMain
          h1={data?.wordPress?.pageBy?.title}
          img={
            cover
              ? cover.attributes.url
              : data.imgCoverMain.childImageSharp.fluid
          }
          alt="General Information"
          className="cover-md"
        >
          <Breadcrumbs path={location.uri} className="breadcrumb-light" />
        </CoverMain>
      )}

      {wordPress?.map((item, i) => {
        switch (item.name) {
          case "core/paragraph":
            return (
              <section key={`core/paragraph-${i}`} className="py-main">
                <div className="container container-md">
                  <HeadingBasic
                    text={[
                      item.attributes.content ||
                        "The Investor Relations is dedicated to shareholders and investors. By contacting our Investor Relations department, you can get updated insights into financial data and other information related to share prices, annual reports, etc.",
                      `For more information, kindly contact our department at investor@sinarmasland.com.sg or by filling out the form below.`,
                    ]}
                    className="mb-0"
                  />
                </div>
              </section>
            )
          default:
            return <div key={`empty-${i}`}></div>
        }
      })}

      <Container>
        <NavTabs scrollable data={navTab.sort(compareByYear)} />
      </Container>

      {navTab.map((item, i) => {
        return (
          <TabPane
            key={`press${item.name}-${i}`}
            id={`${item.target}`}
            className={`py-lg-5 pt-5 pt-lg-0 ${i === 0 ? "active" : ""} rups`}
          >
            <Reports
              data={releaseGroupedByYear && releaseGroupedByYear[item.name]}
            />
          </TabPane>
        )
      })}
    </Layout>
  )
}

export default PressRelease
